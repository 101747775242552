@import './variables.scss';
@import './inputs.scss';
@import './buttons.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import "./offcanvas";

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  overscroll-behavior-x: none;
  background-color: $orange-tint;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

@media (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}


.w-90 {
  width: 90% !important;
}

.fs-sm {
  font-size: 0.875rem;
}

.fs-xs {
  font-size: 0.75rem;
}


::-webkit-input-placeholder {
  font-size: 0.75rem;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.5);
}

.outline-0 {
  outline: 0;
}

.nav-tabs .nav-link {
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 1px solid transparent !important;
  color: $black !important;
}

.nav-tabs .nav-link.active {
  color: $primary !important;
  border-bottom: 1px solid $primary !important;
}

// rotate the nav icon
.nav-item .nav-link .float-end {
  transition: transform 0.3s ease-in-out;
}

.nav-item .nav-link.collapsed .float-end {
  transform: rotate(-90deg);
}

// for input password

.input-group button {
  border-color: $input-border-color;
  border-left: 0;
  box-shadow: none !important;
  background-color: $input-focus-bg;
}

.input-group :focus ~ button {
  border-color: $input-focus-border-color;
  color: $primary;
  background-color: $input-focus-bg;
}

.input-group button:hover {
  border-color: $input-border-color;
}

.input-group button:active {
  border-color: $input-border-color !important;
}
input[type="radio"], input[type="checkbox"] {
  box-shadow: none !important;
}

/*animate*/
.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*progress*/
.loader {
  border: 0.25rem solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-top-color: $primary;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.loader.lg {
  width: 50px;
  height: 50px;
}

.loader.sm {
  width: 18px;
  height: 18px;
}

.loader.transparent {
  border: 0.25rem solid rgba(255, 255, 255, 0.5);
  border-top-color: $primary;
}

/*remove underline*/
a, .btn-link {
  text-decoration: none !important;
}


[contenteditable] [data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: gray;
  cursor: text;
}

.modal-open {
  overflow: hidden;
}


// company logo
.color-twitter {
  color: #1DA1F2;
}

.color-instagram {
  color: #fb3958;
}

.color-facebook {
  color: #4267B2;
}

.color-youtube {
  color: #FF0000;
}

.color-linkedin {
  color: #0077b5;
}

.color-google {
  color: #DB4437;
}

.color-reddit {
  color: #ff4301;
}

.clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.btn.btn-link:active {
  box-shadow: none;
}

.btn.btn-link:focus {
  box-shadow: none;
}

.bg-opacity-20 {
  background: rgba(0, 0, 0, 0.2);
}

.box {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.btn-group .btn {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

.dropdown-menu-right {
  right: 0 !important
}

.dropdown-item p {
  margin-left: 1rem !important;
}

.rounded-full {
  border-radius: 9999px;
}

.arrow-animation img {
  filter: invert(20%);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 25rem;
  object-fit: cover;
  z-index: -1;
  animation: horizontalWipe 5s infinite;
  animation-timing-function: linear;
  will-change: transform;
}

@keyframes horizontalWipe {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}

body.dark-mode {
  .navbar {
    background-color: $dark-primary;
    color: $dark-secondary;
  }

  //background: $dark-secondary;
  //background: $dark-secondary;
  background: linear-gradient(108.07deg, rgba(65, 62, 62, 0.9) 9.86%, #000000 84.91%);
  color: $secondary;

  .btn {
    background: $dark-primary;
    color: $dark-secondary;
  }

  .grayscale {
    filter: none;
  }

  .invert {
    filter: invert(100%);
  }
}

.grayscale {
  filter: grayscale(100%);
}

.grayscale:hover {
  filter: none;
}